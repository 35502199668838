<template>
  <div id="app" ref="resizeTarget">
    <mainCom></mainCom>
    <!-- <div class="bei-bottom">
    <a class="jgawb" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52263302000150" target="_blank"
      style="color: rgb(116, 154, 227) !important;">
      <img class="beian-image" src="./assets/gonganbeian.png" alt="Logo" style="object-fit: contain;">
      &nbsp;贵公网安备52263302000150号
    </a>
    <a class="jicpb" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
      style="color: rgb(116, 154, 227) !important; margin-left: 20px;">
      黔ICP备2023014836号-2&nbsp;&nbsp;&nbsp;
    </a>
  </div> -->
  </div>
</template>

<script>
import mainCom from './components/mainCom.vue'
// 导入 debounce 函数
const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;
    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
export default {
  name: 'App',
  components: {
    mainCom
  },
  mounted() {
    // 获取 ResizeObserver 类
    const _ResizeObserver = window.ResizeObserver;

    // 创建 ResizeObserver 实例，并在回调函数中添加 debounce
    const resizeObserver = new _ResizeObserver(
      debounce((entries) => {
        // 处理 ResizeObserver 的回调逻辑
        // console.log('ResizeObserver callback:', entries);
      }, 16)
    );

    // 获取模板中的元素
    const targetElement = this.$refs.resizeTarget;

    // 开始观察目标元素
    resizeObserver.observe(targetElement);

    // 也可以在组件销毁时停止观察
    // beforeUnmount() {
    //   resizeObserver.disconnect();
    // }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
