<template>
    <div>
        <h3 class="image-description3">全国高等院校选考科目组合要求占比</h3>
        <div id="maychar" style="width: 155vh;height: 600px;margin-top: 10px;"></div>
    </div>
</template>
   
<script>
import echarts from '../echarts'
import userRequest from '@/network/userRequest'
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            datas: [],
        };
    },
    mounted() {
        //构造图表数据
        userRequest.listRequirementNum().then(response => {
            if (response.status == 200) {
                for (let i = 0; i < response.data.length; i++) {
                    let e = {
                        value: 0,
                        name: ''
                    };
                    e.name = response.data[i].requirement;
                    e.value = response.data[i].count;
                    this.datas.push(e);
                }
                console.log(this.datas);
                this.getCharts();
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })


    },
    methods: {
        // 绘制饼图
        getCharts() {
            const chartBox = echarts.init(document.getElementById("maychar"));

            const option = {
                // //标题
                // title: {
                //     text: '全国高等院校科目要求占比',
                //     textStyle: {
                //         fontWeight: "normal",
                //         fontSize: 20,
                //     },
                //     left: "center"
                // },
                //悬浮显示
                tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                        // 根据比例设置显示条件
                        return params.percent >= 0.0001 ? `${params.name}: ${params.percent}%` : `${params.name}: <0.01%`;
                    },
                    // formatter: "{b}:{d}%",
                },
                series: [
                    {
                        type: 'pie',
                        label: {
                            normal: {
                                show: true,
                                formatter: function (params) {
                                    // 根据比例设置显示条件
                                    return params.percent > 0.0001 ? `${params.name}: ${params.percent}%` : `${params.name}: <0.01%`;
                                },
                            },
                        },
                        //最⼩的扇区⾓度（0 ~ 360）
                        minAngle: 2,
                        data: this.datas
                    }
                ]
            };

            console.log("==================")
            chartBox.setOption(option);
            // 根据页面大小自动响应图表大小
            window.addEventListener("resize", function () {
                chartBox.resize();
            });
        },
    },
};
</script>
<style>
.image-description3 {
    position: absolute;
    margin-left: 150px;
    transform: translate(-50%, 50%);
    color: rgb(18, 19, 18);
    background-color: rgba(128, 211, 111, 0.5);
    padding: 5px 10px;
    font-size: 20px;
    font-family: 楷体;
    border-radius: 5px;
    /* 设置一个较高的 z-index 值 */
    z-index: 999;
}
</style>