<template>
    <div>
        <h3 class="image-description4">理想畅言</h3>
        <div style="width: 155vh; display: flex; flex-direction: column; align-items: flex-end;min-width: 610px;">
            
            <div style="margin-left: 500px; display: flex; align-items: center;">
                <el-input class="userInput" v-model="userInput" placeholder="请输入文字" clearable maxlength="30" show-word-limit
                    style="width: 500px;">
                </el-input>
                <el-button type="success" plain @click="addText" style="margin-left: 10px;">
                    添加文字
                </el-button>
            </div>
        </div>

        <div class="canvas-container">
            <canvas ref="canvas" width="1045" height="400" style="margin: 0px 0px;width: 155vh;height: 55vh;min-width: 610px;"></canvas>
        </div>
    </div>
</template>
  
<script>
import userRequest from '@/network/userRequest'
export default {
    data() {
        return {
            userInput: '',
            texts: [] // 存储用户输入的文字
        };
    },
    methods: {
        addText() {
            if (this.userInput.trim() !== '') {
                const canvas = this.$refs.canvas;

                const maxWidth = canvas.width - 50; // 确保文字不靠近右边框
                const textWidth = this.measureTextWidth(this.userInput.trim(), 'italic 20px "Brush Script MT", cursive');

                // this.texts.push({
                //     text: this.userInput.trim(),
                //     x: Math.random() * (maxWidth - textWidth), // 考虑文字宽度
                //     y: Math.random() * 300,
                //     rotation: Math.random() * Math.PI / 4,
                //     color: this.getRandomColor()
                // });
                userRequest.addData({
                    text: this.userInput.trim(),
                    x: Math.random() * (maxWidth - textWidth), // 考虑文字宽度
                    y: Math.random() * 300,
                    rotation: Math.random() * Math.PI / 4,
                    color: this.getRandomColor()
                }).then(response => {
                    if (response.data.resultCode == 200) {
                        this.queryTests();
                        this.$message.success(response.data.message);
                    } else {
                        this.$message.error(response.data.message);
                    }
                }).catch(error => {
                    this.$message.error(error);
                })
                this.userInput = '';
            }
        },
        measureTextWidth(text, font) {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.font = font;
            return ctx.measureText(text).width;
        },
        drawWords() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            // 清空画布
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // 绘制文字及修饰元素
            for (let i = 0; i < this.texts.length; i++) {
                const { text, x, y, rotation, color } = this.texts[i];

                // 设置字体样式
                ctx.font = 'italic 20px "Brush Script MT", cursive';
                ctx.textAlign = 'left';
                ctx.textBaseline = 'top';

                // 绘制文字
                ctx.save();
                ctx.translate(x, y);
                ctx.rotate(rotation);

                // 添加阴影效果
                ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
                ctx.shadowBlur = 5;
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;

                // 添加颜色渐变效果
                const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
                gradient.addColorStop(0, color);
                gradient.addColorStop(1, 'white');
                ctx.fillStyle = gradient;

                ctx.fillText(text, 0, 0);
                ctx.restore();
            }

            // 外边框修饰
            this.drawBorder();
        },
        drawBorder() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            // 绘制整体边框
            ctx.strokeStyle = '#ddd';
            ctx.lineWidth = 2;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
        },
        getRandomColor() {
            // 生成随机颜色
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        queryTests() {
            userRequest.selectTests().then(response => {
                if (response.status == 200) {
                    this.texts = response.data;
                    this.drawWords();
                } else {
                    this.$message.error(response.data.message);
                }
            }).catch(error => {
                this.$message.error(error);
            })
        }
    },
    mounted() {
        this.queryTests();


    }

};
</script>
<style>
.image-description4 {
    position: relative;
    top: 35px;
    margin-left: 55px;
    width: 90px;
    transform: translate(-50%, 50%);
    color: rgb(18, 19, 18);
    background-color: rgba(128, 211, 111, 0.5);
    padding: 5px 10px;
    font-size: 20px;
    font-family: 楷体;
    border-radius: 5px;
    /* 设置一个较高的 z-index 值 */
    z-index: 999;
}

.canvas-container {

    background-image: url('../assets/2.png');
    /* 设置背景图的URL */
    background-size: cover;
    /* 背景图覆盖整个页面 */
    background-position: center;
    /* 背景图居中显示 */
    background-repeat: no-repeat;
    border: 2px solid #ddd;
    border-radius: 1px;
    padding: 0px 0px;
    width: 155vh;
    height: 55vh;
    min-width: 610px;
}
</style>
  