<template>
    <div>
        <h3 class="image-description3">选考科目组合可选院校专业数量图</h3>
        <div id="maychar2" style="width: 155vh;height: 600px;margin-top: 40px;"></div>
    </div>
</template>
   
<script>
import echarts from '../echarts'
import userRequest from '@/network/userRequest'
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            datas: [],
            xData: [],
            yData: [],
        };
    },
    mounted() {
        //构造图表数据
        userRequest.getSuCount().then(response => {
            if (response.status == 200) {
                for (let i = 0; i < response.data.length; i++) {
                    this.xData.push(response.data[i].requirement);
                    this.yData.push(response.data[i].count);
                }
                this.getCharts();
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })


    },
    methods: {
        // 绘制柱状图
        getCharts() {
            const chartBox = echarts.init(document.getElementById("maychar2"));

            // 基本柱状图
            const option = {
                
                xAxis: {
                    data: this.xData,
                    axisLabel: {
                        interval: 0,  // 设置为0表示标签全部显示
                        rotate: 30     // 标签旋转角度
                    }
                },
                yAxis: {},
                series: [
                    {
                        type: "bar", //形状为柱状图
                        data: this.yData,
                        itemStyle: {
                            // 需要修改的颜色可以在这里设置
                            // color: function (params) {
                            //     // params.dataIndex表示柱的索引
                            //     // 可以根据具体需求设置不同的颜色
                            //     return getColorByIndex(params.dataIndex);
                            // }
                            color: "#FFB6C1",
                        }
                    }
                ],
                tooltip: {  // 添加tooltip配置
                    trigger: 'axis',
                    formatter: '{b}: {c}'  // 提示框内容显示横坐标轴标签和数据值
                },
            };
            // function getColorByIndex(index) {
            //     // 根据柱的索引返回颜色，可以根据需要定义颜色数组
            //     const colors = ["#c23531", "#2f4554", "#61a0a8", "#d48265", "#749f83", "#ca8622"];
            //     return colors[index % colors.length];
            // }
            chartBox.setOption(option);
            // 根据页面大小自动响应图表大小
            window.addEventListener("resize", function () {
                chartBox.resize();
            });
        },
    },
};
</script>
<style>
.image-description3 {
    position: absolute;
    margin-left: 150px;
    transform: translate(-50%, 50%);
    color: rgb(18, 19, 18);
    background-color: rgba(128, 211, 111, 0.5);
    padding: 5px 10px;
    font-size: 20px;
    font-family: 楷体;
    border-radius: 5px;
    /* 设置一个较高的 z-index 值 */
    z-index: 999;
}
</style>