<template>
    <div class="mainComClass">
        <el-row justify="center">
            <div class="flex-container">
                <div>
                    <img src="../assets/logo.png" alt="Logo"
                        style="filter: brightness(110%);width: 80px;height: auto;position: relative;">
                </div>
                <div class="col-right">
                    <p class="col-text" @click="fileDowload">政策文件下载</p>
                    <p class="down">&nbsp; <el-divider direction="vertical" />&nbsp; </p>
                    <p class="col-text" @click="fileDowload">贵州省各高校历年录取分数线文件下载</p>
                    <p class="down">&nbsp; <el-divider direction="vertical" /> &nbsp;</p>
                    <p class="col-text"><a href="https://gaokao.gzgkzytb.cn" target="_blank">用户登录</a></p>
                    <p class="down">&nbsp; <el-divider direction="vertical" />&nbsp;</p>
                    <p class="col-text"><a href="https://gly.gzgkzytb.cn" target="_blank">管理员登录</a></p>
                </div>
            </div>
        </el-row>
        <el-divider style="margin-top: -6px;margin-bottom: 10px;" />

        <el-row justify="center">
            <div class="content-container">
                <h3 class="image-description2">网页简介</h3>
                <span>
                    &emsp;&emsp;本平台的开发初衷是为了应对高考改革，为中学生提供更好的学业规划支持。
                    特别是对于那些正在实施新高考改革3+1+2模式的地区考生，我们希望能够为他们提供更加精准的指导和帮助。
                    考虑到不同年龄段学生需求的多样性，我们特别设计了两种匹配方案：<br>
                    &emsp;&emsp;（一）对于高中以下阶段的学生，我们会根据他们所选的学校和专业，为他们推荐相关的科目组合。
                    这样可以帮助学生提前了解自己未来的学习方向和重点，提高他们的学习效果和目标达成度。同时，
                    这也可以帮助他们更好地了解不同学科之间的联系，为他们将来的发展打下坚实的基础。<br>
                    &emsp;&emsp;（二）对于高中阶段的学生，我们会根据他们个人的学科选择，为他们推荐可报考的学校和专业。
                    这样可以帮助他们更加准确地定位自己的目标和方向，提高他们的录取成功率。同时，这也可以帮助他们
                    更好地了解不同学校和专业的特色和优势，为他们将来的职业发展做出更加明智的选择。<br>
                    &emsp;&emsp;我们期待本平台能够为广大学子提供帮助，希望每位用户都能够顺利考取心仪的大学！未来，我们将持续充实平台内容，
                    整合与高考相关的一系列政策文件，并不断优化网站功能，为用户提供更优质的使用体验。
                    <br>
                    &emsp;&emsp;备注：在匹配结果中，部分记录可能会缺少专业代码等相关字段，
                    这是因为某些记录下的专业属于一个大类或者是一个班级。若需了解专业大类信息，可前往“专业目录”中的“专业类别”查找。<br>
                </span>
            </div>
            <el-col :span="24"></el-col>

            <el-carousel class="carousel_main" :interval="3000" type="card" @change="updateCurrentIndex"
                height="calc(42vh)">
                <h3 class="image-description1">网页操作指南</h3>
                <el-carousel-item v-for="(item, index) in imgwrap" :key="index">
                    <div class="carousel-item-wrapper">
                        <img :src="item.url" class="carousel-image" @click="goLogoin" />
                        <p class="image-description" v-show="currentIndex === index">{{ item.description }}</p>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </el-row>
        <el-divider />
        <el-row justify="center">
            <ehartsCom></ehartsCom>
        </el-row>

        <el-divider style="margin-top: -50px;"/>
        <el-row justify="center">
            <ehartsCom2></ehartsCom2>
        </el-row>
        <el-divider  />
        <el-row justify="center">
            <wordCloud></wordCloud>
        </el-row>
        <el-divider />

        <el-row justify="center">
            <div class="bei-bottom">
                <a class="jgawb" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52263302000150"
                    target="_blank" style="color: rgb(116, 154, 227) !important;">
                    <img class="beian-image" src="../assets/gonganbeian.png" alt="Logo" style="object-fit: contain;">
                    &nbsp;贵公网安备52263302000150号
                </a>
                <a class="jicpb" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
                    style="color: rgb(116, 154, 227) !important; margin-left: 20px;">
                    黔ICP备2023014836号-2&nbsp;&nbsp;&nbsp;
                </a>
            </div>
        </el-row>

    </div>
</template>

<script>
import wordCloud from './wordCloud.vue'
import ehartsCom from './ehartsCom.vue'
import ehartsCom2 from './ehartsCom2.vue'
import '../assets/home.css'
export default {
    //组件注入
    components: {
        ehartsCom,
        wordCloud,
        ehartsCom2
    },
    //变量初始化
    data() {
        return {
            currentIndex: 0,
            imgwrap: [
                {
                    url: require("../assets/carousel_images/0.png"),
                    description: '一、点击右上角用户登录'
                },
                {
                    url: require("../assets/carousel_images/1.png"),
                    description: '二、用户登录界面'
                },
                {
                    url: require("../assets/carousel_images/2.png"),
                    description: '三、点击注册'
                },
                {
                    url: require("../assets/carousel_images/3.png"),
                    description: '四、注册个人信息，选择自己对应的身份类型'
                },
                {
                    url: require("../assets/carousel_images/4.png"),
                    description: '五、初中生主页面'
                },
                {
                    url: require("../assets/carousel_images/5.png"),
                    description: '六、初中生一键匹配'
                },
                {
                    url: require("../assets/carousel_images/6.png"),
                    description: '七、高中生主页面'
                },
                {
                    url: require("../assets/carousel_images/7.png"),
                    description: '八、高中生一键匹配'
                },
                {
                    url: require("../assets/carousel_images/8.png"),
                    description: '九、高等院校专业选考科目要求表'
                },
                {
                    url: require("../assets/carousel_images/9.png"),
                    description: '十、普通高等学校本科专业目录'
                },
            ]

        }
    },
    //方法定义
    methods: {
        goLogoin() {
            // 打开新标签页并跳转到指定链接
            //window.open("http://gaokao.lazhfu.xyz/", "_blank");
        },
        updateCurrentIndex(index) {
            this.currentIndex = index;
        },
        fileDowload() {

        },
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
}

</script>

<style>
.content-container {
    width: 155vh;
    background-color: rgb(252, 252, 252);
    text-align: left;
    padding: 10px;
    padding-top: 40px;
    box-sizing: border-box;
    /* 确保宽度中包含填充 */
}

.down {
    color: #e5e2e2;
}

.col-right {
    margin-left: 67vh;
    display: flex;
}

.col-text {
    font-size: 15px;
    color: #353030;
    cursor: pointer;
}

.col-right :hover {
    color: rgb(0, 217, 255);
}

.col-text a {
    text-decoration: none;
    color: #353030;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}


.col-text a:hover {
    color: rgb(0, 217, 255);
}

.mainComClass {
    width: 100%;
    background-image: url('../assets/background.jpg');
    /* background-color: #67C23A; */
    height: calc(100vh);
    font-weight: bold;
    /* 设置背景图的URL */
    background-size: cover;
    /* 背景图覆盖整个页面 */
    background-position: center;
    /* 背景图居中显示 */
    background-repeat: no-repeat;
    min-width: 1000px;
}

.carousel_main {
    top: 10px;
    height: auto;
    width: 155vh;
}

.carousel-item-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 10PX;
}

.carousel-image {
    display: block;
    width: 100%;
    height: auto;
}

.image-description1 {
    position: absolute;
    top: -12%;
    margin-left: 70px;
    transform: translate(-50%, 50%);
    color: rgb(18, 19, 18);
    background-color: rgba(128, 211, 111, 0.5);
    padding: 5px 10px;
    font-size: 20px;
    font-family: 楷体;
    border-radius: 5px;
    /* 设置一个较高的 z-index 值 */
    z-index: 999;
}

.image-description2 {
    /* top: -15%;
    left: 7%; */
    position: absolute;
    /* width: 80px; */
    top: -20px;
    margin-left: -10px;
    color: rgb(18, 19, 18);
    background-color: rgba(128, 211, 111, 0.5);
    padding: 5px 10px;
    font-size: 20px;
    font-family: 楷体;
    border-radius: 5px;
}

.image-description2 a {
    text-decoration: none;
}

.image-description {
    position: absolute;
    bottom: 10%;
    margin-left: 50%;
    transform: translate(-50%, 50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    width: auto;
}

.el-carousel__item:nth-child(n) {
    background-color: #d3dce6;
}

.mx-1 {
    background-color: aliceblue;
    width: 1000px;
    height: auto;
    background-color: aliceblue;
    text-align: center;
}


.bei-bottom {
    text-align: center;
    height: 20px;
    margin-bottom: 0px;
    float: bottom;
    margin-top: 20px;
}

.bei-bottom a {
    text-decoration: none;
    color: #353030;
}

.beian-image {
    margin-left: 5px;
    top: 5px;
    width: 16px;
    height: auto;
    position: relative;
    top: 2px;
}
</style>